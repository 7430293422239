
import { Vue, Component } from 'vue-property-decorator'
import router from '@/router'
import { logger } from '@/utils/logger'
import app from '@/store/modules/app'
import modules from '@/store/modules'
import authService from '@/services/auth'
import AlertBox from '@/components/AlertBox.vue'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'

@Component({
  components: {
    AlertBox,
  },
  computed: {
    authService() {
      return authService
    },
  },
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${'Reset Password'} - %s`,
    }
  },
})
export default class ResetPassword extends Vue {
  isHashValid: boolean | null = null
  isFormValid = false
  password = ''
  confirmPassword = ''
  passwordHidden = true
  success = false
  errorMessage = false
  isSubmitting = false
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong',
    },
  }
  shouldValidatePassword = false

  get supportEmail(): string {
    return modules.systemParameter.supportEmail
  }

  get supportNumber(): string {
    return getSupportNumber(modules.auth.customer?.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  mounted(): void {
    this.validateCustomer()
  }

  async validateCustomer(): Promise<void> {
    try {
      const userHash = this.$route?.params?.id
      const validateCustomerResult = await authService.validateCustomer({
        userHash,
        verifyCustomerDetails: false,
      })
      this.isHashValid = validateCustomerResult?.data?.successful
    } catch (e) {
      this.isHashValid = false
      logger.warn(e)
    }
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    this.asyncPasswordResponse = await authService.validatePassword(
      this.password
    )
    this.shouldValidatePassword = true

    this.success = false
    this.errorMessage = false
    const form = this.$refs.form as any
    try {
      const formIsValid = await form.validate()
      if (formIsValid) {
        this.isSubmitting = true
        await authService.resetPassword({
          hash: this.$route.params.id,
          password: this.password,
        })
        this.success = true
        this.isSubmitting = false
        form.reset()

        setTimeout(() => router.push({ name: 'home' }), 2000)
      }
    } catch (error) {
      this.errorMessage = true
      this.isSubmitting = false
      logger.error(error)
    }
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }
}
